<template>
  <b-modal
      id="true-money-webhook-modal"
      hide-footer
      size="xl"
      title="ลงทะเบียนระบบออโต้ True Money Webhook"
  >
    <b-overlay :show="isFetching">
      <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onRegister)">
        <ValidationProvider
            v-slot="{ errors }"
            name="pin"
            rules="required"
        >
          <b-form-group label="JWT">
            <b-form-input
                v-model="form.jwtToken"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                type="text"
            ></b-form-input>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <b-button
            v-clipboard:copy="webhookUrl"
            v-clipboard:success="onCopy"
            class="mr-2"
            type="button"
            variant="info">
          คัดลอก Webhook URL
        </b-button>
        <b-button variant="success" type="submit" :disabled="isFetching">
          {{ $t('buttons.submit') }}
        </b-button>
      </b-form>
    </ValidationObserver>
    </b-overlay>
  </b-modal>

</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'TrueWalletModal',
  props: {
    agentBankAccountId: {
      type: String,
      required: true
    },
    trueMoneyWebhook: {
      type: Object,
      default: () => ({
        jwtToken: '',
      })
    }
  },
  data() {
    return {
      isFetching: false,
      form: {}
    }
  },
  computed: {
    webhookUrl() {
      return process.env.VUE_APP_TRUE_MONEY_WEBHOOK_URL + '/' + this.agentBankAccountId
    }
  },
  created() {
    this.form = {
      jwtToken: this.trueMoneyWebhook?.jwtToken,
    }
  },
  methods: {
    ...mapActions(['registerTrueWebhook', 'fetchBankAccount']),
    async onRegister() {
      this.isFetching = true
      await this.registerTrueWebhook({
        id: this.agentBankAccountId,
        data: this.form,
      })
      this.$bvModal.hide('true-money-webhook-modal')
      await this.fetchBankAccount(this.agentBankAccountId)
      this.isFetching = false
    },
    onCopy() {
      this.$bvToast.toast('คัดลอก Webhook URL เรียบร้อย', {
        variant: 'success',
        title: 'Success'
      })
    },
  }
}
</script>

<style scoped>

</style>